@import "./variables/_colors.scss";
@import "./functions/mixinsModule";
body {
  //   background-color: red;
}
* {
  font-family: "OpenSans Regular" !important;
}
ul {
  list-style: none;
}
.max-w-input-of {
  max-width: 528px;
  width: 100%;
  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
}
.animate-op {
  animation: animateOp 0.3s ease-in;
  @keyframes animateOp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-none-click {
  cursor: not-allowed !important;
}
.disable-click-on-label {
  cursor: not-allowed !important;
  label {
    pointer-events: none;
  }
}

.nav-tabs {
  .nav-item {
    margin: 0 !important;
  }
}
@media screen and (max-width: 1298px) {
  .panel-card {
    min-width: 100% !important;
  }
}
.login-form-tabs {
  height: 49px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: none;

  .nav-item {
    width: 33%;
    &:first-child .nav-link {
      border-radius: 5px 0px 0px 5px !important;
    }
    &:last-child .nav-link {
      border-radius: 0px 5px 5px 0px !important;
    }
    .nav-link {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 47px;
      color: #bbbbbb;
      border-radius: 0;
      border: 1px solid #bbbbbb;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }

      &.active {
        border: none;
        background-color: font_clr(purple-pri);
        border-radius: 0;
        color: #fff;
      }
    }
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  pointer-events: none;
}
.w-48 {
  width: 48%;
}
.w-49 {
  width: 48.9%;
}
.w-40 {
  width: 40%;
}
.w-80 {
  width: 80%;
}
.radio-4 {
  width: 70%;
  ._RadioButtonsFormSection__Wrapper_1g9ga_1,
  ._RadioButtonsFormSection__Wrapper_Inline_yibtp_7,
  ._RadioButtonsFormSection__Wrapper_Inline__CizMF {
    display: flex;
    flex-wrap: wrap;
  }
}
.radio-full-width {
  width: 80%;
  // ._RadioButtonsFormSection__Wrapper_1g9ga_1,
  ._RadioButtonsFormSection__Wrapper_Inline_yibtp_7 {
    display: flex;
    flex-wrap: wrap;
  }
}
.w-100-800 {
  width: 100%;
  max-width: 800px;
}
.radio-half {
  width: 100% !important;
  max-width: 100% !important;
  ._RadioButtonsFormSection__Wrapper_Inline_mjl3m_7 {
    max-width: 528px;
    flex-wrap: wrap;
  }
  ._RadioButtonsFormSection__Wrapper_Inline_yibtp_7 {
    max-width: 528px;
    flex-wrap: wrap;
  }
  .RadioButtons_RadioButtonsFormSection__Wrapper_Inline__CizMF {
    max-width: 528px;
    flex-wrap: wrap;
  }
}

.w-4 {
  width: 24%;
  @media (max-width: 1780px) {
    width: 23.7%;
  }
  @media (max-width: 1580px) {
    width: 32%;
  }
  @media (max-width: 1395px) {
    width: 48.7%;
  }
}
.w-3 {
  width: 31%;
  @media (max-width: 1325px) {
    width: 48%;
  }
}
.w-32 {
  width: 31.9%;
  @media (max-width: 1400px) {
    width: 48%;
  }
}
.w-8 {
  width: 10.5%;
}
.m-w-100 {
  max-width: 100% !important;
  width: 100% !important;
}

.border-red {
  input {
    border: 1px solid red !important;
  }
}

.m-w-i {
  max-width: 100% !important;
  width: 100% !important;
  label {
    text-transform: capitalize;
  }
  input,
  textarea {
    max-width: 528px;
  }
}
.w-100 {
  .lm-input-form-input-label {
    text-transform: capitalize;
  }
}
.m-w-i-half {
  max-width: 100% !important;
  width: 100% !important;
  input,
  textarea {
    width: 48%;
  }
}
.w-2 {
  width: 20%;
}

.m-w-i-s {
  max-width: 100% !important;
  width: 100% !important;
  input,
  textarea {
    width: 23%;
  }
}
.custom-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.ant-spin {
  height: 100%;
  flex: 2;
  .ant-spin-dot {
    width: 50px;
    height: 50px;
    .ant-spin-dot-item {
      background-color: #662d91 !important;
      width: 50%;
      height: 50%;
    }
  }
}
.w-30 {
  width: 30%;
}
.w-15 {
  width: 15%;
}
.flex-end-class {
  justify-content: flex-end !important;
}
.modal-content {
  .ant-spin-dot {
    width: 50px;
    height: 50px;
    .ant-spin-dot-item {
      background-color: #662d91 !important;
      width: 50%;
      height: 50%;
    }
  }
}
.DashbaordCardSelect {
  &.ant-select-open {
    .ant-select-selector {
      border-color: #af79d8 !important;
      box-shadow: 0 0 0 2px rgba(102, 45, 145, 0.15) !important;
    }
  }
  .ant-select-selection-item {
    padding-right: 0px !important;
  }
  .ant-select-item-option {
    border-bottom: 1.59454px solid #e0e0e0;
    border-radius: 0px;
    &:last-child {
      border: none !important;
    }
    &-selected {
      color: #662d91 !important;
      font-weight: 600;
      background-color: transparent !important;
    }
  }

  .ant-select-selector {
    min-height: 38px;
    &:hover {
      border-color: #af79d8 !important;
    }
  }
  .ant-select-single {
  }
  .ant-select-arrow {
    color: #333333;
    width: 9px;
  }
  .ant-select-selection-selected-value {
    background-color: red !important;
  }
  .ant-select-selector {
    align-items: center;
  }
}
.lm-progress-bar-dashboard {
  .progress {
    background: #f4f5fe;
    border-radius: 6px;
    height: 5px;
    // justify-content: end;
    &-bar {
      background-color: #f6891f;
    }
    &.mastered-status {
      background: #d5ffc6 !important;
      .progress-bar {
        background-color: #5eca2b !important;
      }
    }
    &.intermediate {
      background: #fff3ca !important;
      .progress-bar {
        background-color: #fab609 !important;
      }
    }
    &.low {
      background: #ffcaca !important;
      .progress-bar {
        background-color: #e54545 !important;
      }
    }
  }
}
.apexcharts-canvas {
  max-width: 100% !important;
  .apexcharts-svg {
    max-width: 100%;
  }
}

.dashboard-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #686868;

  .btn {
    border-radius: 0;
    padding: 5px 18px;
    height: 42px;
    border: 1px solid #686868;
    background: #f2f2f2;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }
    &.active {
      color: #662d91;
      border-color: #662d91;
      background-color: #f1f0fc;
    }
  }
  &-container {
    // height: 52px;
    background-color: #fff;
    gap: 8px;
    padding: 8px;
    .btn {
      color: #333333;
      font-weight: 600;
      border: none !important;
      background: #ffffff;
      border: 1px solid #f2f4f7;
      height: 36px !important;
      border-radius: 6px !important;
      &:first-child {
        border-radius: 6px !important;
      }
      &:last-child {
        border-radius: 6px !important;
      }
      &.active {
        font-weight: 700;
        border-color: #662d91;
        background-color: #f1f0fc;
        background: #f7f1fb;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
      }
    }
  }
}

.DashboardSidebar {
  .active {
    @include animate-op;
    background-color: #662d91;
  }
}
.notfications-Icon {
  &.unread {
    &::after {
      content: "";
      height: 6px;
      width: 6px;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      background-color: #ef3f29;
      top: 2px;
    }
  }
}

.ant-breadcrumb {
  .ant-breadcrumb-separator {
    margin-bottom: 4px;
  }
  ol {
    align-items: center;
    li {
      .ant-breadcrumb-link {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        color: #9a9a9a;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      &:last-child {
        .ant-breadcrumb-link {
          color: #af79d8;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}

.lm-nav-arrow {
  width: 45px;
  height: 45px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #e8dfef;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rounded-avatar-md {
  width: 100%;
  height: 45px;
  max-width: 45px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.rounded-avatar-lg {
  width: 100%;
  height: 115px;
  max-width: 115px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.rounded-avatar-letters {
  width: 100%;
  height: 115px;
  max-width: 115px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  justify-content: center;
  object-fit: contain;
  display: flex;
  align-items: center;
  font-size: 50px;
  background: purple;
  color: white;
}
.rounded-avatar-xsm {
  width: 100%;
  height: 20px;
  max-width: 20px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.rounded-avatar-sm {
  width: 100%;
  height: 36px;
  max-width: 36px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.InnerWrapper {
  // background: #f8f7fa;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
}
.lm-table-tooltip {
  .ant-tooltip-inner {
    padding: 12px !important;
    ul {
      list-style-type: disc;
      padding: 18px;
    }
  }
}

.custom-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 517px;
  opacity: 0.7;
  z-index: 99999;
  backdrop-filter: blur(4px) !important;
  -webkit-filter: blur(4px) !important;
  -moz-filter: blur(4px) !important;
  -o-filter: blur(4px) !important;
  -ms-filter: blur(4px) !important;
  background: #fbfbfb;
}
.lm-tabs {
  .ant-tabs {
    &-ink-bar {
      background: #f6891f !important;
      height: 5px !important;
      // min-width: 53% !important;
    }
    &-tab {
      margin: 0 !important;
      padding-bottom: 18px;
      &:hover {
        color: #f6891f !important;
      }
      .ant-tabs-tab-btn {
        color: #aaaaaa !important;
      }
      &-active {
        .ant-tabs-tab-btn {
          font-weight: 700 !important;
          color: #f6891f !important;
        }
      }
    }
    &-nav {
      &-list {
        gap: 40px !important;
      }
      &-wrap {
        // border-bottom: 2px solid #e4e1e1;
        margin-top: 25px;
      }
    }
  }
}

.units-link-btn {
  border: none;
  background: transparent;
  color: #f6891f;
}

.forgot-password-title {
  color: #101828;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}
.ant-table {
  height: calc(100vh - 270px) !important;
  overflow: auto;
}
.ant-table-cell {
  background-color: #fff !important;
  border: none !important;
}
.small-w {
  @media screen and (max-width: 1000px) {
    width: 100% !important;
  }
}
.comment-w {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
.ant-pagination {
  padding: 28px 0;
  margin-bottom: 0px !important;
}
.last-child:last-child {
  margin-bottom: 0 !important;
}

.double-field {
  flex-wrap: wrap;
  row-gap: 12px !important;
  column-gap: 24px;
  height: 42px;

  input {
    width: 160px;
  }
  .formik_error {
    text-align: center;
  }
}
.ant-steps-item.filled {
  .ant-steps-item-title {
    color: white !important;
  }
}
.sub-step-wrapper
  .ant-steps
  .ant-steps-item.filled
  .ant-steps-item-content
  .ant-steps-item-title {
  color: white !important;
}

.ant-steps
  .ant-steps-item.ant-steps-item-active.ant-steps-item-filled
  .ant-steps-item-content::after {
  all: unset !important;
}

.sub-step-wrapper
  .ant-steps
  .ant-steps-item.ant-steps-item-finish.filled
  .ant-steps-item-content::before {
  background: #70bf44 !important;
}
.sub-step-wrapper
  .ant-steps
  .ant-steps-item.ant-steps-item-finish.filled::before {
  background: #70bf44 !important;
}
.sub-step-wrapper
  .ant-steps
  .ant-steps-item.filled
  .ant-steps-item-content::before {
  background: #70bf44 !important;
}
.sub-step-wrapper
  .ant-steps
  .ant-steps-item.filled-not-finished
  .ant-steps-item-content::before {
  background: #f6891f !important;
}
.sub-step-wrapper .ant-steps .ant-steps-item.filled::before {
  background: #70bf44 !important;
}
.sub-step-wrapper .ant-steps .ant-steps-item.filled-not-finished::before {
  background: #f6891f !important;
}

.disabled-next-btn {
  &:disabled {
    opacity: 0.3;
    border-color: #f6891f !important;
    background-color: unset !important;
  }
}

.profile-save-btn {
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      opacity: 0.5 !important;
    }
  }
}

.abc-radio-btn {
  margin-top: 30px;
  .dont-use {
    flex-wrap: wrap;
  }
  .lm-check-input {
    width: 32%;
    flex-basis: 32%;
    max-width: 100%;
  }
}
.counter-input-abc {
  input {
    text-align: center;
  }
}

.abc-text-input {
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  div {
    width: 33%;
  }
  @media screen and (max-width: 780px) {
    justify-content: center;
  }
}

.additional-tech-clients {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 5px;
  background: #efefef;
}

.tech-last-clients {
  color: #f6891f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 5px;
  background: #f9ede3;
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
}

.upcoming-sessions-scroll {
  height: calc(100vh - 150px) !important;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // .infinite-scroll-component {
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
}

.technician-client {
  display: flex;
  flex-direction: column;
  gap: 2px;
  span {
    color: #939393;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
  }
}
.technicians-client-status {
  text-transform: capitalize;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &.in-progress {
    color: #f6891f;
  }
  &.mastered {
    color: #5eca2b;
  }
}

.cursor-simple {
  cursor: auto !important;
}
.signature-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  pointer-events: auto;
  z-index: 9;
  cursor: not-allowed !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-more-session {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 278px;
  margin-top: 20px;
  color: #9a9a9a;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  text-transform: capitalize;
  background-color: white;
}

.mands-select {
  .custom-select-wrapper {
    margin: 0 !important;
    width: 100% !important;
    max-width: 280px !important;
    label {
      margin-top: 0 !important;
    }
  }
}

.acceptNo {
  padding: 12px 24px;
  margin-bottom: 24px;
  .acceptNoSpan {
    cursor: pointer;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .ant-input {
    font-size: 20px !important;
  }
}
.acceptNo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.units-p {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
}

.add-units-select {
  width: 100%;
  .custom-select-wrapper {
    width: 100% !important;
  }
}

.hover-unset {
  background: none;
  &:hover {
    border: none !important;
    outline: none;
  }
}

.z-index-1 {
  z-index: 1 !important;
}

.file-upload-container {
  align-self: flex-end;
  display: flex;
  // width: 200px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  height: 48px;
  gap: 10px;
  border-radius: 5px;
  background: #f6891f;
  border: honeydew;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  position: relative;
  text-transform: capitalize;
  .selected-name {
    font-size: 12px;
    margin-right: 12px;
  }
  button {
    background: transparent;
    border: none;
    svg {
      path {
        fill: white;
      }
    }
  }
}

.profile-modal {
  .modal-body {
    padding: 30px 0 !important;
    .modal-header {
      padding: 0 30px !important;
    }
    .profile-modal-section {
      padding: 30px !important;
      padding-bottom: 0 !important;
      height: calc(100vh - 155px);
      overflow: auto;
    }
  }
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0 !important;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.client-doc-action-btns {
  display: flex;
  align-items: center;
  gap: 12px;
  button {
    border: none;
    background-color: transparent;
  }
  .download-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8dfef;
    border-radius: 6px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.mobile-100 {
  // @media (max-width: 1000px) {
  width: 100% !important;
  label {
    display: flex;
    width: 100%;
    white-space: nowrap;
    gap: 5px;
  }
  // }
}

.timer-btn {
  display: flex;
  width: 100px;
  padding: 5px 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: #f6891f;
  border: honeydew;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}
.timer-btn-2 {
  display: flex;
  width: 100px;
  padding: 5px 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f6891f;
  // border: honeydew;
  background-color: transparent;
  color: #f6891f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}

.ant-slider-track {
  background-color: #f6891f !important;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #f6891f;
  width: 16px;
  height: 16px;
}
.ant-slider-horizontal .ant-slider-rail,
.ant-slider-horizontal .ant-slider-track {
  height: 8px;
}
.ant-slider .ant-slider-dot {
  width: 12px;
  height: 12px;
}
.ant-slider-horizontal .ant-slider-mark {
  padding-top: 8px;
}

.ant-slider .ant-slider-dot-active {
  border-color: #f6891f;
}

.mands-select {
  .custom-select-wrapper {
    max-width: 100% !important;
  }
}

.ant-select-dropdown {
  z-index: 99999999;
  text-transform: capitalize;
}
.ant-search-dropdown-wrapper {
  width: 100% !important;
  text-transform: capitalize;

  .ant-select-selector {
    min-height: 42px;
    color: #333333;
    width: 100%;
    align-items: center;
  }
  .ant-select-selection-item {
    text-transform: capitalize;
  }
}
.ant-modal-content {
  padding: 0 !important;
  .ant-modal-header {
    padding: 20px 24px;
  }
  .profile-modal-section {
    padding: 20px 24px;
    height: calc(100vh - 100px);
    overflow: auto;
  }
}

.insurance-radio {
  .ant-radio-inner {
    border: 1px solid #dddddd !important;
    background-color: transparent !important;
    width: 24px;
    height: 24px;
    &::after {
      transform: scale(0.7) !important;
    }
  }
  &.ant-radio-wrapper-checked {
    .ant-radio-inner {
      border: 1px solid #f6891f !important;
      background-color: #f6891f !important;
      width: 24px;
      height: 24px;
      &::after {
        transform: scale(0.7) !important;
      }
    }
  }
}
.add-main-btn {
  display: flex;
  width: 100%;
  padding: 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px dashed #cecdcd;
  background: transparent;
  svg {
    width: 18px;
    height: 18px;
  }
  margin-bottom: 8px;
}
.textCap {
  input {
    text-transform: capitalize;
  }
}
