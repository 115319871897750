
// .countdown-container {
//     border: 2px solid orange;
//     padding: 10px;
//     display: inline-block;
//     margin-right: 40px; 
//   }
  
//   .countdown-text {
//     /* Add any additional styles for the text if needed */
//   }

.countdown-container {
  background-color: #ff8c00;
  color: #fafafa;
  font-size: 18px;
  font-weight: bold;
  margin-right: 40px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.countdown-container:hover {
  background-color: #e07b00;
}

.countdown-text {
  margin: 0;
}

.countdown-text b {
  color: #f5f1f1;
}
