.row {
  display: flex;
}

.col-md-6 {
  padding: 5px;
}

.dashboard-wrapper {
  width: 100%;
  overflow: auto;
}

.analysis-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

h3 {
  color: #555;
}

.time-score-graph,
.progress-report,
.attendance-overview,
.insights-warnings {
  background-color: #f9f9f9;
  padding: 10px 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100%;
}

.dashboard-client-selection {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.time-score-graph-filters {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.progress-report-filters button {
  padding: 8px 15px;
  margin-right: 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.progress-report-filters {
  width: 100%;
  margin-bottom: 5px;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;

  .mastered-btn {
    background-color: #5eca2b;
  }

  .mastered-btn:focus {
    background-color: #4ba224;
    border-color: #2e7015;
    outline: none;
    box-shadow: 0 0 0 3px rgba(94, 202, 43, 0.5);
  }

  .in-progress-btn {
    background-color: #f6891f;
  }

  .in-progress-btn:focus {
    background-color: #dc7700;
    border-color: #b35f00;
    outline: none;
    box-shadow: 0 0 0 3px rgba(246, 137, 31, 0.5);
  }

  .assigned-btn {
    background-color: #8e847a;
  }

  .assigned-btn:focus {
    background-color: #73695f;
    border-color: #5b5148;
    outline: none;
    box-shadow: 0 0 0 3px rgba(142, 132, 122, 0.5);
  }
}

.progress-filters button:hover {
  background-color: #0056b3;
}

.progress-report table {
  width: 100%;
}

.progress-report table th,
.progress-report table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.progress {
  height: 50px;
}

.warning-goals-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 250px;
  overflow: scroll;
  min-width: 250px;
  margin: 5px
}

.warning-goals-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.warning-goals-tag {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  display: inline-block;
}

.insight-warning-filters {
  width: 100%;
  margin-bottom: 10px;
  /* background: #ffffff; */
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;

  .insight-btn {
    background-color: #12d8b7;
    margin-left: 80px;
  }

  .warning-btn {
    background-color: #f7cb05f6;
    margin-right: 80px;
  }

}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.custom-tooltip .label {
  margin: 0;
  color: #333;
}

.time-score-graph-filters {
  .multi-selector {
    .css-1dyz3mf {
      overflow: scroll;
      height: 69px;
      width: 400px;
    }
  }
}

.progress-text {
  font-weight: bold;
}

.empty-goals-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 250px;
  margin: 5px
}

.empty-goals {
  font-size: 48px;
  font-weight: bold;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}