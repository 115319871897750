.GoalCard {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  // min-height: 58px;
  display: flex;
  gap: 16px;
  padding-right: 14px;
  &__Header {
    display: flex;
    align-items: center;
    gap: 8px;
    // min-width: 150px;
    background: #faf3ff;
    border-radius: 10px 0px 0px 10px;
    padding: 0px 14px;
    img {
      filter: drop-shadow(0px 8px 16px rgba(16, 16, 35, 0.2));
    }
  }
  &_Progress {
    width: 100%;
    max-width: 100px;
  }
}
.avatarImg {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-family: Open Sans;
  font-weight: 600;
  padding: 12px;
  background: #090834;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
