@import "../../assets/styles/functions/mixinsModule";
.ant-custom-table {
  .ant-table {
    background: white !important;
    table {
      border-spacing: 0px 16px;
      thead {
        tr {
          th {
            &:last-child {
              text-align: center;
            }
            &.ant-table-cell {
              background-color: transparent;
              padding-bottom: 0px;
              padding-top: 0px;
              line-height: 0px;
              border: none;
              font-size: 14px;
              font-weight: 600;
              color: #73797e;
              padding-left: 22px;
              &::before {
                display: none;
              }
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          background-color: #fff;
          .ant-table-cell {
            &:last-child {
              text-align: center;
            }
            border: 1px solid transparent;
            padding: 12px 12px 12px 22px;
            line-height: 14px;
            font-size: 15px;
            line-height: 30px;
            font-weight: 400;
            &:first-child {
              border-radius: 10px 0px 0px 10px;
              font-weight: 700;
            }
            &:last-child {
              border-radius: 0px 10px 10px 0px;
            }
          }
        }
      }
    }
  }
}
.ant-table-wrapper {
  .ant-pagination {
    margin-right: 32px !important;
    &-item {
      border: 1px solid #dddddd;
      &-active {
        background: #f1f0fc;
        border: 1px solid #662d91 !important;
        color: #333333;
        &:hover {
          color: #662d91;
          a {
            color: #662d91;
          }
        }
      }
    }
    li {
      padding: 5px 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border-radius: 0;
      border: 1px solid #dddddd;
      &:first-child {
        border-radius: 5px 0px 0px 5px;
      }
      &:last-child {
        border-radius: 0px 5px 5px 0px;
      }
      &.ant-pagination-prev {
        border-right: 0;
      }
      &.ant-pagination-next {
        border-left: 0;
      }
      &.ant-pagination-disabled {
        background: #f2f2f2;
        border: 1px solid #dddddd;
      }
      &.ant-pagination-options {
        display: none;
      }
    }
  }
  .add-icon {
    border-radius: 6px;
    border: 1px solid #f6891f;
    background: #fff6ee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .lm-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 5px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #662d91;
    background: #f7f1fb;
    border-radius: 5px;
    cursor: pointer;
  }
  .disabled-row {
    background-color: #f4f4f4 !important;
    // opacity: 0.7 !important;
    .ant-table-cell {
      color: #ababab !important      ;
    }
    .ant-table-cell-tag {
      background-color: #d8d8d8 !important;
      color: #919191 !important;
    }
  }
}
.lm-listing {
  &-row-bordered-bottom {
    table {
      thead {
        .ant-table-cell {
          border: none;
          &::before {
            display: none !important;
          }
        }
      }
      tbody {
        td {
          border-bottom: 1px solid #ececec !important;
        }
      }
    }
  }
}
.lm-listing {
  &.ant-table-wrapper {
    // background-color: red;
    border-radius: 0px 0px 10px 10px;
    background-color: #fff;
    @media (max-width: 1200px) {
      height: 100%;
    }
    & > div:first-child {
      height: 100%;

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        ul {
          margin-top: auto;
          @media (max-width: 1000px) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .ant-table {
    height: 100%;
    @include animate-op;
    background: transparent !important;
    .ant-table-container {
      height: 100%;
      .ant-table-content {
        height: 100%;
        .css-dev-only-do-not-override-1sn0ohr.ant-empty.ant-empty-normal {
          height: calc(100vh - 418px);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        // table {
        //   height: 100%;
        // }
      }
    }
    table {
      thead {
        tr {
          th {
            &:last-child {
              text-align: center;
              background-color: #fff;
            }
            &:first-child {
              padding-left: 30px !important;
            }
            &.ant-table-cell {
              background-color: white;
              // padding-bottom: 0px;
              // padding-top: 0px;
              line-height: 0px;
              border: none;
              font-size: 14px;
              line-height: 30px;
              font-weight: 600;
              color: #73797e;
              padding-left: 22px;
              padding-bottom: 5px;
              &::before {
                display: none;
              }
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          background-color: #fff;
          .ant-table-cell {
            height: 75px;
            padding: 12px 12px 12px 22px;
            line-height: 14px;
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            color: #667085;
            background-color: #fff;

            border: none;
            .client-info-data {
              display: flex;
              align-items: center;
              gap: 14px;
              cursor: pointer;
              &.disable-cursor {
                cursor: default;
              }
              .name-show {
                width: 100%;
                max-width: 35px;
                aspect-ratio: 1;
                border-radius: 50%;
                object-fit: contain;
                background-color: #662d91;
                line-height: 1.2px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
              }
              img {
                width: 100%;
                max-width: 35px;
                aspect-ratio: 1;
                border-radius: 50%;
                object-fit: cover;
                // background-color: #662d91;
              }
              &-upcoming {
                img {
                  max-width: 43px !important;
                }
              }
            }
            .tocolumn {
              @media (max-width: 1000px) {
                // flex-direction: column;
                // gap: 12px !important;
                width: max-content !important;

                .badge {
                  svg {
                    width: 12px;
                  }
                  span {
                    display: none;
                  }
                }
                // display: none !important;
              }
            }
            &:last-child {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
            }
            &:first-child {
              // border-radius: 10px 0px 0px 10px;
              font-weight: 600;
              color: #333333;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
.ant-table-tbody {
  .badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;

    &-upcoming {
      padding: 2px 8px;
      gap: 6px;
      background: #f4f5fe;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        color: #333333;
      }
    }
    &-service-note {
      padding: 5px 16px;
      gap: 12px;
      background: #f7f1fb;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        text-transform: capitalize;
        color: #662d91;
      }
    }
  }
  .ant-table-row {
    .ant-table-cell {
      &:not(:last-child) {
        max-width: 465px !important;
      }
      &-tag {
        border: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 6px;
        gap: 7px;
        height: 26px;
        border-radius: 5px;
        max-width: fit-content;
        // margin-left: 16px;
        &-compliance {
          color: #5eca2b;
          background: #d5ffc6;
        }
        &-alert {
          color: #fa0909;
          background-color: #ffcaca;
        }
        &-completed {
          background-color: #e3f4d9;
          color: #70bf44;
        }
        &-inprogress {
          background-color: #fef8e9;
          color: #f6ba1f;
        }
        &-started {
          background-color: #f9ede3;
          color: #f6891f;
        }
      }
    }
  }
}

.actions-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  &-red {
    background-color: #fbf1f1;
  }
  &-green {
    background-color: #ebffee;
  }
  &-yellow {
    background-color: #fff9eb;
  }
  &-orange {
    background-color: #ffebd8;
  }
}

.upcoming-session-table {
  .ant-table-thead {
    display: none;
  }
}

.client-billing-table .ant-table-thead .ant-table-cell {
  color: black !important;
}

.client-billing-table .ant-table {
  overflow: hidden !important;
}

.progress-report-table {
  

}
.progress-report-table .ant-table {
  height: unset !important;
  overflow: unset !important;
}

.progress-report-table .ant-pagination {
  margin: unset !important;
  padding: 23px;
}

.progress-report-table .ant-table-thead .ant-table-cell {
  border: solid 1px black !important;
}

.progress-report-table .ant-table-tbody .ant-table-cell {
  border: solid 0.8px black !important;
}
