.RadioButtonsFormSection {
  //   background-color: red;
  &__Wrapper {
    // max-width: 527px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 12px;
    column-gap: 32px;
    &_Inline {
      display: flex;
      justify-content: start;
      gap: 12px;
      &.optionalPills {
        flex-wrap: wrap;
      }
    }
  }
  &.errorRadio {
    input[type="radio"] {
      border-color: red !important;
    }
    input[type="checkbox"] {
      border-color: red !important;
    }
  }
}
.simpleCheck {
  :global(.form-check-label) {
    display: none;
  }
}
