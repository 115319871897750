.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  background-color: #e4e7ec;
  max-height: 3px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #7a99d9),
    color-stop(0.72, #497dbd),
    color-stop(0.86, #7f56d9)
  );
  border-radius: 10px;
}
.modal {
  backdrop-filter: blur(4px) !important;
}
.tox-notifications-container {
  display: none !important;
}
.modal-backdrop.show {
  -webkit-filter: blur(1px) !important;
  -moz-filter: blur(1px) !important;
  -o-filter: blur(1px) !important;
  -ms-filter: blur(1px) !important;
  background: #aaa;
  opacity: 0.1;
}
.custom-modal-body {
  max-width: 900px;
  .modal-content {
    height: 475px;

    .custom-modal-content {
      height: auto;
    }
    .modal-sub-heading {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 16px 16px 20px;
    }
  }
}

.modal-footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  padding: 8px 12px 8px 8px;
  gap: 2px;
  background: #f6891f;
  border-radius: 5px;
  right: 27px;
  position: absolute;
  z-index: 9999;
  bottom: 6px;
  button {
    border: none;
    background-color: transparent;
    color: white;
  }
}

.GegreeofProgress-Outer {
  .lm-input-form-input-label {
    color: #888585 !important;
  }
}