.PanelCard {
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  max-height: 366px;
  min-width: 313px;
  // max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  // max-width: 427px;
  &_Chart_Container {
    // min-height: 290px !important;
  }
}
.DashbaordCardSelect {
  .ant-select-selector {
    min-height: 47px;
  }
}
