.StepCompletionModal {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 350px;
  padding-left: 60px;
  &__textSection {
    margin-top: 64px;
  }
  &__step {
    &_indicator {
      font-family: "Inter", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 65px;
      line-height: 60px;
      text-transform: capitalize;
      color: #333333;
      &Number {
        color: #70bf44;
      }
    }
    &_nextCaption {
      font-family: "Inter", sans-serif !important;
      font-weight: 600;
      font-size: 19px;
      line-height: 85px;
      color: #333333;
    }
  }
}
.thankYouModal {padding: 0 !important;}

.emailEditorModal{
  & > div {
    max-width: 775px;
    width: calc(100% - 15px);
  }
}