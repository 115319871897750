#otp-modal {
  .modal-content {

    #modal-type {
      #m-content {
        //background-image: url('Splash@2x.png');
        border-radius: 0px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;

        #header-signin {
          background-color: #FFFFFF;
          font-family: roboto-bold;
          width: 100%;

          #button-close {
            border: none;
            outline: none;
            background: none;
            color: #9B9B9B;
            font-family: roboto-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 18px;
            margin-right: 10px;
            margin-top: 6px;
          }
        }

        #body-otp {
          flex-direction: column;
          display: flex;
          background: none;

          #otp-title {
            background: none;
            margin-bottom: 45px;
            color: #4A4A4A;
            font-family: roboto-bold;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: -1px;
            line-height: 38px;
            text-align: center;
          }

          #otp-container {
            margin-bottom: 20px;
            font-size: 14px;
            color: #9B9B9B;
            background: none;
            font-family: roboto-regular;
            box-sizing: border-box;
            /* height: 43px; */
            width: 100%;
            /* border: 1px solid #E7E7E7; */
            border-radius: 21px;
            display: flex;
            /* justify-content: flex-start; */
            /* align-items: center; */
            margin-top: 10px;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            #login-icon {
              background: none;
              margin: 10px;
            }

            #user-otp {
              width: 80%;
              height: 100%;
              background: none;
              border-style: none;
            }

            #user-otp:focus {
              background: none;
              outline: none;
            }

            #sign-identifier {
              display: none;
            }
          }

          #user-otp::placeholder {
            color: #9B9B9B;
          }

          .error-div {
            font-size: 16px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: red
          }

          .error-div-otp {
            font-size: 16px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: red
          }

          #message-sent {
            font-size: 14px;
            color:#3c3c3c
          }

          #resend-code {
            font-size: 14;
            color: #7acbcb;

            .send-code{
              cursor: pointer;
            }
          }

          .otp-footer {
            display: flex;
            justify-content: flex-end;

            .otp-cancel {
              background: white;
              color: black;
              font-family: roboto-regular;
              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 15px;
              text-align: center;
              height: 45px;
              width: 100px;
              margin-bottom: 10px;
              margin-top: 10px;
              border: none;
              outline: none;
            }

            .otp-submit {
              background: #f6891f;
              color: white;
              font-family: roboto-regular;
              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 15px;
              text-align: center;
              height: 45px;
              width: 100px;
              margin-bottom: 10px;
              margin-top: 10px;
              border: none;
              outline: none;
            }
            .otp-submit:disabled {
              color: #fff;
              border-color: #a0a0a0;
              background-color: #a0a0a0;
            }
          }

          .image-div {
            // background-image: url("/assets/signin_background.png");
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 400px;
  column-gap: 20px;
}

.otp-input {
  width: 100%;
  height: 120px;
  border: 2px solid #f6891f;
  box-shadow: 0 2px 8px #f6891f;
  border-radius: 5px;
  text-align: center;
  font-size: 60px;
  font-weight: normal;
  line-height: 1;
}

.otp-cancel {
  background: white;
  color: black;
  font-family: roboto-regular;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  height: 45px;
  width: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: none;
  outline: none;
}