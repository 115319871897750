@import "../functions/_mixinsModule.scss";

input[type="date"] ::-webkit-inner-spin-button,
input[type="date"] ::-webkit-calendar-picker-indicator {
  visibility: none;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  content: "";
  background-image: url("../../images/calender-icon-input.svg");
  background-color: #fff;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input,
textarea {
  @include animate-op;
}
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-datetime-edit {
  display: none;
}
input[type="date"]:before {
  // content: attr(placeholder);
}
input[type="date"] {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btn:disabled:hover,
.btn:disabled:focus,
.btn.disabled:hover,
.btn.disabled:focus {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.btn:disabled,
.btn.disabled {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.form-check-input:focus {
  box-shadow: none;
  border-color: #7f56d9 !important;
}
.lm-check-input {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
  max-width: fit-content;
  @media screen and (max-width: 1000px) {
    // padding: 16px 10px 16px 15px;
    gap: 2px !important;
  }
  @include animate-op;
  .form-check-label {
    font-weight: 400;
    @media screen and (max-width: 1000px) {
      // padding: 16px 10px 16px 15px;
      font-size: 12px;
      min-width: 50px;
    }
    color: #333333;
    line-height: 20px;
    min-width: 100px;
    margin-top: 4px;
  }
  .form-check-input {
    min-height: 24px;
    min-width: 24px;
    @media screen and (max-width: 1000px) {
      // padding: 16px 10px 16px 15px;
      min-height: 15px;
      min-width: 15px;
    }
    &[type="radio"] {
      border-radius: 24px;
    }
    &[type="checkbox"] {
      border-radius: 7px;
    }
    border: 1px solid #dddddd;
    &:checked[type="radio"],
    &:checked[type="checkbox"] {
      border: 1px solid #f6891f;
      background-color: #f6891f !important;
    }
    @media screen and (max-width: 1000px) {
      // padding: 16px 10px 16px 15px;
      gap: 2px !important;
    }
  }
}
.Search-Input-Wrapper {
  position: relative;
  max-width: 241px;
  svg {
    position: absolute;
    left: 12px;
    top: 10px;
    z-index: 99;
  }
}
.lm-input,
.react-tagsinput {
  text-align: left !important;
  min-height: 42px;
  color: #333333;
  position: relative;
  padding: 8px 15px !important;
  border: 1px solid #dddddd !important;
  border-radius: 8px !important;
  font-family: "OpenSans Regular", sans-serif;
  // z-index: 99;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  &.lm-search-input {
    padding-left: 38px !important;
  }
  @include animate-op;
  &:focus {
    box-shadow: none;
    border-color: #7f56d9 !important;
    // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 1px #ceced6 !important;
  }
  &.error-field {
    border-color: red !important;
  }
}
.lm-input-form-input-label {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  position: relative;
  &.required {
    &::after {
      content: "*";
      position: absolute;
      top: 0;
      right: -9px;
      color: #ca0808;
    }
  }
}

input::placeholder {
  color: #dddddd;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 20px !important;
  opacity: 0.8 !important;
}

.btn-close {
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    box-shadow: none;
  }
}
.lm-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 44px;
  border-radius: 6px;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:focus,
  &:focus-visible,
  &:focus-within {
  }
  &-secondary {
    color: #bbbbbb;
    background-color: #ffffff;
    border: 1px solid #bbbbbb;

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      box-shadow: none;
      color: #bbbbbb !important;
      border-color: #bbbbbb !important;
      background-color: #ffffff !important;
    }
    &-draft {
      &:hover {
        box-shadow: none;
        color: white !important;
        border-color: #f6891f !important;
        background-color: #f6891f !important;
      }
    }
  }
  &-primary {
    width: 200px;
    color: #ffffff;
    background-color: #f6891f;
    border: 1px solid #f6891f;
    min-width: fit-content;
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active,
    &:hover {
      box-shadow: none;
      border-color: #f6891f !important;
      background-color: #f6891f !important;
      opacity: 0.9 !important;
      color: #ffffff !important;
    }
  }
  &-previous {
    background-color: #ffffff;
    border: 1px solid #f6891f;
    width: 44px;
    padding: 0;
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover {
      box-shadow: none;
      border-color: #f6891f !important;
      background: #ffffff !important;
    }
  }
  &-add {
    background-color: #ffffff;
    height: 40px;
    min-width: 100%;
    border: 1px dashed #cecdcd;
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover {
      box-shadow: none;
      background: #ffffff !important;
    }
  }
  &-modalbtn {
    padding: 10px 18px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    &-cancel {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #344054;
      &:active,
      &:hover,
      &:focus,
      &:focus-visible {
        background: #ffffff !important;
        color: #344054 !important;
        box-shadow: none !important;
        border: 1px solid #d0d5dd !important;
      }
    }
    &-Accept {
      border: 1px solid #2bce28;
      background: #2bce28;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #ffffff;
      &:hover {
        background: #2bce28 !important;
        border-color: #2bce28 !important;
        color: #ffffff !important;
      }
    }
    &-Reject {
      background: #f61f1f;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-color: #f61f1f;
      &:hover {
        background: #f61f1f !important;
        border-color: #f61f1f !important;
        color: #ffffff !important;
      }
    }
    &-Archive {
      border: 1px solid rgb(102, 45, 145);
      background: rgb(102, 45, 145);
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #ffffff;
      &:hover {
        background: rgb(102, 45, 145) !important;
        border-color: rgb(102, 45, 145) !important;
        color: #ffffff !important;
      }
    }
    &-UnArchive {
      border: 1px solid #f61f1f;
      background: #f61f1f;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #ffffff;
      &:hover {
        background: #f61f1f !important;
        border-color: #f61f1f !important;
        color: #ffffff !important;
      }
    }
    &-reSendMail {
      border: 1px solid #f6891f;
      background: #f6891f;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #ffffff;
      &:hover {
        background: #f6891f !important;
        border-color: #f6891f !important;
        color: #ffffff !important;
      }
    }
  }
}
.tag-checkbox {
  background-color: #ededed;
  height: 33px;
  border-radius: 27px;
  padding: 6px 12px;
  overflow: hidden;
  display: block;
  cursor: pointer;
  position: relative;
  user-select: none;
  @include animate-op;
  input:focus {
    border-radius: 27px !important;
  }
  &.checked {
    background-color: #f6891f !important;
    .form-check-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: url("../../images/cross-bgwhite.svg");
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 24px;
    }
    .form-check-label {
      color: #ffffff;
    }
  }
  .form-check-input {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: -4px;
    left: 24px;
    right: 0;
    bottom: 0px;
    cursor: pointer;
    &:checked[type="checkbox"] {
      background: none;
      background-color: transparent !important;
      border: none;
    }
  }
  .form-check-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    margin: 0;
    min-width: max-content;
    cursor: pointer;
    svg {
      @include animate-op;
    }
  }
}

.ant-picker-dropdown {
  z-index: 999999 !important;
}
.ant-switch {
  &.ant-switch-checked {
    background-color: #f6891f !important;
  }
}
.lm-date-picker {
  // width: 100%;
  height: 42px;
  padding: 0;
  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within,
  &.ant-picker-focused {
    border-color: #7f56d9 !important;
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: #7f56d9 !important;
      &::before {
        border: none !important;
      }
    }
  }
  .ant-picker-cell-inner {
    &::before {
      border-color: #7f56d9 !important;
    }
  }
  .ant-picker-today-btn {
    color: #7f56d9;
    text-decoration: none;
    font-weight: 600;
    &:hover {
      color: #7f56d9;
    }
  }
  .ant-picker-input {
    padding: 8px 15px;
    input {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #333333;
    }
    .ant-picker-clear {
      right: 50px;
    }
  }
}

.FormCheck.form-check.custom-radio-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  .form-check-input {
    width: 24px;
    height: 24px;
    border: 1px solid #dddddd;
    &:hover {
      border-color: #f6891f;
    }
    &:checked {
      background-color: #f6891f;
      border: none;
    }
    &:checked + label {
      font-weight: bold;
    }
  }
  .form-check-label {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin-top: 3px;
  }
}
.breadcrumb-item + .breadcrumb-item {
  a {
    text-decoration: none;
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  background: url("../../svg/breadcrumbicon.svg") no-repeat;
  width: 24px;
  height: 24px;
  margin-top: 6px;
}

.dashboard-footer {
  position: fixed;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f4f5fe;
  border-top: 1px solid #dddddd;
  width: calc(100% - 362px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 50px 10px 0;
  z-index: 99999;
  @media screen and (max-width: 1200px) {
    width: calc(100% - 250px);
    padding: 4px;
  }
  @media screen and (max-width: 1000px) {
    width: 100% !important;
    padding: 4px;
  }

  .send-back-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    color: #ef3f29;
    background: #f4f5fe;
    border: 1px solid #ef3f29;
    border-radius: 6px;
    margin-right: 16px;
  }

  .checkedin-locked-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    color: #ef3f29;
    background: #f4f5fe;
    border: 1px solid #ef3f29;
    border-radius: 6px;
    margin-right: 16px;
  }
  
  .approve-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    background: #f6891f;
    border-radius: 6px;
    color: #fff;
    border: none;
    width: 110px;
    height: 46px;
    &:disabled {
      opacity: 0.8;
      background-color: #bbbbbb;
    }
  }
}
.accordian-wrapper {
  .custom-accordian-data-sheet.accordion {
    .accordion-item {
      border: 1px solid #cecdcd;
      border-radius: 10px;
      height: auto;
      overflow: hidden;
    }
  }
  .ant-input {
    text-align: center;
  }
}
