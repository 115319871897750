@import "../../assets//styles//functions/_mixinsModule.scss";
.Dashboard {
  display: flex;
  height: 100vh;
  background-color: #f4f5fe;
  overflow: hidden;
  &__Sidebar {
    background-color: #090834;
    max-width: 248px;
    width: 100%;
    padding-top: 32px;
    position: relative;
    transition: 0.4s ease-in-out;
    height: 100vh;
    // overflow-y: auto;
    // overflow-x: hidden;
    @media screen and (max-height: 1300px) {
      // min-width: 220px;
    }
    @media screen and (max-width: 1200px) {
      max-width: 220px;
    }

    &__Profile {
      &ImageSection {
        position: relative;
        &_Image {
          width: 87px;
          height: 87px;
          object-fit: cover;
          border-radius: 50%;
        }

        &_SettingsIcon {
          width: 25px;
          height: 25px;
          position: absolute;
          right: 0;
          bottom: 0;
          background: #ffffff;
          box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    &_Wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      height: 100%;
    }
    &__Listing {
      width: 100%;
      // height: calc(100vh - 400px);
      overflow-y: auto;
      &_ListItem {
        height: 70px;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 15px;
        color: #fff;
        padding-left: 56px;
        cursor: pointer;

        @media screen and (max-height: 1200px) {
          padding-left: 46px;
        }

      }
    }
    &__Close_Nav_Bar {
      max-width: 50px !important;
      min-width: 100px;
      .Close_Nav_Element {
        display: none !important;
      }
      .Dashboard {
        &__Sidebar {
          &__Close_Icon {
            transform: rotate(180deg);
          }
        }
      }
      .StarIcon {
        display: block !important;
      }
      .list_items {
        padding-left: 40px !important;
      }
    }
    &__collapseiconwrapper {
      position: absolute;
    }
    &_collapseicon {
      background-color: #662d91;
      cursor: pointer;
      height: 40px;
      width: 40px;
      position: absolute;
      right: -15px;
      top: 20px;
      z-index: 999999;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transform: rotate(180deg);
      box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    }
  }

  &__NotificationsTab {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -571px;
    width: 571px;
    background-color: #fff;
    padding: 30px 0px;
    z-index: 999999;
    // overflow: auto;
    &Listing {
      display: flex;
      flex-direction: column;
    }
  }
  &__Notifications {
    &_Notification {
      padding: 12px 30px;
      min-height: 114px;
      background-color: #fff;
      user-select: none;
      &_Unread {
        cursor: pointer;
        background-color: #f7f1fb;
      }
      &_TitleWrapper {
        max-width: 93%;
      }
    }
  }
  &__Panel {
    width: 100%;
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    @include animate-op;
    &__ContentWrapper {
      width: 100%;
      padding: 46px 30px 30px 30px;
      @include animate-op;
      // display: grid;
      // grid-template-columns: auto;
      // grid-template-rows: 0.5fr 2fr 2fr;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 100vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__Board {
      background: #ffffff;
      box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
      border-radius: 10px;
      padding: 30px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      // row-gap: 60px;
      // column-gap: 60px;
      @media screen and (max-width: 1280px) {
        padding: 16px;
        justify-content: center;
        gap: 10px;
      }
      span {
        @media screen and (max-width: 1280px) {
          font-size: 32px !important;
        }
      }
      p {
        @media screen and (max-width: 1280px) {
          font-size: 16px !important;
        }
      }
    }
    &_CardWrapper {
      display: flex;
      gap: 30px;
      @media screen and (max-width: 1298px) {
        flex-wrap: wrap;
        .panel-card {
          min-width: 100%;
        }
      }
    }
    &__RightSidebar {
      min-width: 362px;
      background-color: #fff;
      height: 100vh;
      overflow-y: auto;
      padding: 0px 50px 24px 30px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media screen and (max-width: 1224px) {
        padding: 16px;
        min-width: 250px;
      }
      &_hideOverflow {
        overflow-y: none !important;
      }

      &_Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        background: #fff;
        padding-top: 52px;
        padding-bottom: 42px;
      }
      &_Listing {
        &__ListItem {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 12px 0px 14px 0px;
          border-top: 1px solid #e0e0e0;
        }
      }
    }
  }
}
.ClientListing {
  // padding: 60px 50px 30px 30px;
  width: 100%;
  max-height: 100vh;
  // overflow-y: auto;
  &Container {
    padding: 60px 50px 30px 30px;
    display: flex;
    gap: 30px;
    min-width: 100%;
    @media screen and (max-width: 1200px) {
      padding: 16px;
      gap: 16px;
      overflow-y: auto;
    }
  }

  &__Wrapper {
    background: white;
    box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
      height: calc(100vh - 200px);
    }
    :global .ant-spin {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-spin-dot {
        width: 50px;
        height: 50px;
        .ant-spin-dot-item {
          background-color: #662d91 !important;
          width: 50%;
          height: 50%;
        }
      }
    }
  }
  &__Header {
    display: flex;
    align-items: center;
    padding: 22px 30px 18px 30px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    &_InputSection {
      width: 100%;
      @media screen and (max-width: 1250px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__RightSidebar {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media screen and (max-width: 1000px) {
      gap: 10px;
      width: 110px;
      margin-right: 7px;
    }
    &_board {
      display: flex;
      flex-direction: column;
      // height: 373px;
      gap: 30px;
      width: 223px;
      background: #ffffff;
      box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
      border-radius: 10px;
      padding: 24px 24px 30px 24px;
      @media screen and (max-width: 1100px) {
        padding: 24px;
        width: 150px;
      }
      @media screen and (max-width: 1000px) {
        padding: 24px;
        width: 120px;
      }
    }
    &_clientUnits {
      display: flex;
      flex-direction: column;
      gap: 2px;
      // padding: 16px;
    }
    &_countNumber {
      font-size: 35px;
      font-weight: 900;
      color: #af79d8;
      @media screen and (max-width: 1100px) {
        font-size: 22px;
      }
    }
    &_Button {
      height: 49px;
      border-radius: 5px;
    }
  }
}
.ProfileSettings {
  @include animate-op;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  width: 655px;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 850px;
  transform: translate(-50%, -50%);
  &__Container {
    max-width: 64%;
    margin: 0 auto;
    padding: 70px 0px 70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  &__ImageSection {
    position: relative;
    img {
      width: 115px;
      height: 115px;
      max-width: 115px;
      max-height: 115px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__InputSection {
    height: 26px;
    width: 26px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(16, 24, 40, 0.06),
      0px 0px 16px -4px rgba(16, 24, 40, 0.1);
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    cursor: pointer;

    svg {
      position: absolute;
      top: 5px;
      left: 5px;
      cursor: pointer;
    }
    input {
      opacity: 0;
      position: relative;
      z-index: 9;
      cursor: pointer;
    }
    input#file-upload-button {
      cursor: pointer;
    }
  }
}
.ProfileContainer {
  @include animate-op;
  display: flex;
  // gap: 30px;
  width: 100%;
  &__LeftSidebar {
    min-width: 457px;
    max-width: 457px;
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    display: flex;
    flex-direction: column;
    gap: 32px;
    // justify-content: space-between;
    padding: 32px 32px 50px 32px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &_Header {
      display: flex;
      align-items: center;
      gap: 21px;
    }
    &_Section {
      padding-bottom: 32px;

      border-bottom: 1px solid #eaecf0;
      &_Information {
        display: flex;
        justify-content: space-between;
        &_label {
          display: block;
          min-width: 148px;
        }
        &_Info {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  &__Technician {
    &Badge {
      display: flex;
      gap: 7px;
      padding: 4px 6px;
      height: 28px;
      background: #efefef;
      border-radius: 5px;
    }
  }
  .lastWorkedGoals {
    min-height: 350px !important;
    overflow: auto;
  }
  &__ClientPanel {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 30px 50px 30px 30px;
    min-width: calc(100% - 457px);
    &_Card {
      // flex: 1;
      // width: 655px;
      background: #ffffff;
      border-radius: 12px;
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      // min-height: 389px;
      // max-height: fit-content;
      &Header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.ClientDailySession {
  padding: 32px 50px 32px 30px;
  width: 100%;
  @media screen and (max-width: 1000px) {
    padding: 16px 16px 16px 15px;
  }
}

.YourClients {
  // background-color: black;/
  .ClientListing {
    &__Header {
      justify-content: space-between;
      h4 {
        width: 100%;
      }
      &_InputSection {
        justify-content: flex-end;
      }
    }
  }
}

.ClientBillingFilters {
  display: flex;
  align-items: center;
  // flex-direction: column;
  // height: 373px;
  gap: 30px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  padding: 20px 20px 20px 30px;
  &_totalAmountDiv {
    width: 15%;
    border: solid;
    border-color: #f6891f;
  }
  &_totalAmountHeadingDiv {
    margin-bottom: 50px;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  &_totalAmountValueDiv {
    text-align: center;
  }
  &_totalAmountValue {
    font-weight: bold;
    margin-bottom: 10px;
  }
  &_filterItem {
    width: 180px;
    &_clientId {
      padding-bottom: 22px;
      width: 170px;
    }
  }
}

.ClientBilling {
  // padding: 60px 50px 30px 30px;
  width: 100%;
  max-height: 100vh;
  // overflow-y: auto;
  &Container {
    display: flex;
    flex-direction: column;
    padding: 16px 50px 30px 30px;
    gap: 18px;
    min-width: 100%;
    @media screen and (max-width: 1200px) {
      padding: 16px;
      gap: 16px;
      overflow-y: auto;
    }
  }
}

.filterStatement {
  font-size: small;
  color: red;
}

.clientBillingButtons {
  display: flex;
  gap: 30px;
  padding-bottom: 22px;
  margin-right: 25px;
}
