.container-config {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  height: 500px;
}

.card-config {
  width: 800px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .form-label {
    font-weight: bold;
    font-size: 15px;
  }
//   .form-input {
//     width: 40%;
//   }
}

.card-title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 25px;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; // Move button to the right
}

.button-save {
  width: 100px; // Set width to 100px
}
