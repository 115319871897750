.checkemail-section {
  position: relative;
  background-color: #f4f5fe;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .checkemail-image-top {
    position: absolute;
    top: -90px;
    left: 40px;
    width: 10%;
    transform: rotate(180deg);
  }
  .checkemail-image {
    position: absolute;
    bottom: -80px;
    right: 40px;
    width: 20%;
  }
  .checkemail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .check-your-email {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .email-icon {
        width: 56px;
        height: 56px;
        background: rgba(246, 137, 31, 0.2);
        border: 10px solid rgba(246, 137, 31, 0.1);
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
      }
    }
  }
}

.check-your-email-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-bottom: 12px;
  .check-your-email-reset-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475467;
  }
}
.email-btn {
  background: #f6891f;
  border: 1px solid #f6891f;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: none;
  width: 100%;
  padding: 10px 0;
  margin: 32px 0;
  color: #fff;
  
  .pointer{
    cursor: pointer !important;
  }
  .default{
    cursor: default !important;
  }
}
.receive-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 32px;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #f6891f;
  }
  p{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #f6891f;
  }
}
.back-to-login {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  padding-left: 8px;
  cursor: pointer !important;

}
