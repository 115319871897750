.onboarding {
  &-wrapper {
    display: flex;
  }

  &-sidebar {
    background-color: #090834;
    min-height: 100vh;
    min-width: 460px;
    padding: 36px 32px 32px 70px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1240px) {
      min-width: 340px;
      padding-left: 32px;
    }
    .logo {
      width: fit-content;
    }
  }
  &-forms-section {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      // height: 134px;
      padding: 30px 30px 0px 64px;
      background-color: #fff;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      @media screen and (max-width: 992px) {
        padding: 24px;
      }
      &__heading {
        font-style: normal;
        // font-weight: 700;
        font-size: 27px;
        line-height: 150%;
        @media screen and (max-width: 992px) {
          font-size: 20px;
        }
      }
      &__crossIcon {
        max-height: fit-content;
        cursor: pointer;
        align-self: flex-start;
      }
    }
    &-heading {
      font-weight: 700;
      font-size: 22px;
      line-height: 150%;
      color: #333333;
    }
    &-wrapper {
      height: calc(100vh - 227px);
      overflow-y: auto;
      padding: 0px 70px 16px 64px;
      @media screen and (max-width: 992px) {
        padding: 0px 24px 16px 24px;
      }
    }
    &-inner-header {
      background: #fff;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      padding-top: 40px;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid #dddddd;
      padding: 29px 70px 26px 64px;
      z-index: 999;
      background: #fff;
      @media screen and (max-width: 992px) {
        padding: 24px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
  &-form {
    &-header {
      display: flex;
      justify-content: space-between;

      &-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #333333;
      }

      &-breadcrumb {
        ul {
          display: flex;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          align-items: center;
          text-align: right;
          color: #662d91;
          li {
            color: #a4a4a4;
          }
        }
      }
      &-title {
        width: fit-content;
        height: 24px;
        padding: 7px;
        background: #662d91;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-subsection-heading {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
    }
  }
}

.active {
  display: block;
}
.notActive {
  display: none;
}
.ant-steps {
  flex-direction: column;
  height: 100%;
  .ant-steps-item {
    cursor: pointer;
    flex: none;
    padding-inline-start: 0 !important;
    padding-bottom: 32px;

    position: relative;
    &::before {
      content: "";
      width: 1px;
      height: 50px;
      background: #a18ead;
      position: absolute;
      left: 8px;
      top: 18px;
    }

    &:last-child {
      &::before {
        all: unset;
      }
    }

    .ant-steps-item-content {
      padding-left: 37px;
      position: relative;
      &::before {
        content: "";
        width: 18px;
        height: 18px;
        border: 1px solid #a18ead;
        border-radius: 5px;
        background: transparent;
        position: absolute;
        left: 0;
        top: 0px;
      }
      .ant-steps-item-title {
        padding: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-transform: capitalize;
        color: #a18ead !important;
      }
    }
    &.ant-steps-item-active {
      &::before {
        content: "";
        background: #f6891f;
      }
      .ant-steps-item-content {
        &::before {
          border-color: #f6891f;
        }
        &::after {
          content: "";
          width: 9px;
          height: 9px;
          position: absolute;
          background: #f6891f;
          border-radius: 3px;
          left: 4.5px;
          top: 4.5px;
        }
        .ant-steps-item-title {
          color: #ffffff !important;
        }
      }
      &.with-substeps {
        padding: 0;
      }
    }
    &.ant-steps-item-finish,
    &.ant-steps-item-filled {
      &::before {
        content: "";
        background: #70bf44;
      }
      .ant-steps-item-content {
        &::before {
          background-color: #70bf44;
          border: none;
          background-image: url("../../assets/images/tick.svg");
          background-repeat: no-repeat;
          // background-size: cover;
          background-position: center center;
        }
        .ant-steps-item-title {
          color: #ffffff !important;
        }
      }
    }
  }
  .ant-steps-item-icon {
    display: none;
  }
  .ant-steps-item-title {
    &::after {
      all: unset !important;
    }
  }
}
.sub-step-wrapper {
  margin-top: 30px;
  .ant-steps {
    padding-left: 0;

    .ant-steps-item {
      margin-left: -37px;

      &:last-child {
        &::before {
          content: "";
          width: 1px;
          height: 50px;
          background: #a18ead;
          position: absolute;
          left: 8px;
          top: 18px;
        }
      }

      .ant-steps-item-content {
        position: relative;
        &::before {
          background: #a18ead;
          border-radius: 3px;
          width: 10px;
          height: 10px;
          border: none;
          top: 50%;
          left: 3.5px;
          transform: translateY(-50%);
        }
        &::after {
          all: unset !important;
        }
        .ant-steps-item-title {
          font-size: 16px;
          padding: 0;
          color: #a18ead !important;
        }
      }
      &.ant-steps-item-active {
        padding-bottom: 32px;

        &::before {
          background: #a18ead;
        }
        .ant-steps-item-content {
          &::before {
            background: #f6891f;
            border: none;
          }

          .ant-steps-item-title {
            color: #ffffff !important;
          }
        }
      }
      &.ant-steps-item-finish,
      &.ant-steps-item-filled {
        &::before {
          content: "";
          background: #f6891f;
        }
        .ant-steps-item-content {
          &::before {
            background: #f6891f;
            border: none;
          }
          .ant-steps-item-title {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}
.two-col-wrapper {
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #333333;
    &:last-child {
      margin-right: 40px;
    }
  }
  .two-col-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
}
.style-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input {
    width: 90px;
  }
  p {
    display: none;
  }
}

.form-sidebar-overflow {
  height: calc(100vh - 230px);
  margin-top: 80px;
  overflow: auto;
}
.add-custom-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px dashed #cecdcd;
  border-radius: 6px;
  background-color: transparent;
  padding: 12px 0;
}
.logout-button {
  color: rgb(246, 137, 31);
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  padding-left: 46px;
  padding-top: 12px;
}
.remove-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 12px;
  // border-top: 1px solid #e2e2e2;
  margin-bottom: -40px;
  position: relative;
  z-index: 9;
  button {
    width: 28px;
    height: 28px;
    background: url("../../assets/images/newCross.svg");
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    background-color: none;
  }
}
.text-editor-outer {
  width: 100%;
  height: 681px;
}
.onboarding-forms-section-footer {
  display: flex;
  // flex-direction: column-reverse;
  align-items: flex-end;
  // border: none;
  .modal-content {
    width: calc(100% - 78%);
    margin-bottom: 40px;
    padding: 8px 16px;
    background: #ffffff;
    box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.06);
  }
}

.notes-modal {
  position: fixed;
  right: 0px;
  bottom: 108px;
  width: 350px;
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.06);
  padding: 8px 16px;
  height: 630px;
  z-index: 100;
  background-color: #ffffff;
  @media screen and (max-width: 992px) {
    height: 500px;
  }
  @media (max-height: 900px) {
    height: 500px;
  }
  &.hide-textarea {
    textarea {
      display: none !important;
    }
  }
}
