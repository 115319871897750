.custom-tabs-data-sheet {
  &.nav-tabs {
    border-bottom: 2px solid #e4e1e1;

    .nav-item {
      .nav-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.001em;
        color: #aaaaaa;
        border: none;
        border-bottom: 5px solid transparent;
        text-align: start;
        padding: 0px 10px 20px;
        @media (max-width: 1000px) {
          padding: 0 2px 20px;
          font-size: 14px;
        }
        &.active {
          color: #f6891f;
          font-weight: 700;
          border-bottom: 5px solid #f6891f;
        }
      }
    }
  }
}

.custom-accordian-data-sheet {
  &.accordion {
    margin-top: 16px;
    overflow-y: auto;
    height: 60vh;
    &::-webkit-scrollbar {
      width: 0;
    }
    .accordion-item {
      border-radius: 10px;
      border: 1px solid #cecdcd;
      background: #fff;
      box-shadow: 0px 8px 20px 0px rgba(102, 45, 145, 0.06);
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .accordion-header {
        // height: 40px;
        // margin-bottom: 16px;

        .dropdown-wrapper {
          .custom-select-wrapper {
            width: 100%;
            label {
              display: none;
            }
            .custom-dropdown-btn {
              width: 100%;
              min-height: 42px;
              // overflow: hidden;
            }
          }
        }

        .accordion-button:not(.collapsed) {
          background-color: transparent;
          box-shadow: none;
        }
        .accordion-button {
          &::after {
            width: 24px;
            color: #1c1b1f;
            height: 24px;
          }
          &:focus {
            box-shadow: none;
            border: none;
            background-color: transparent;
          }
          &.collapsed {
            border-radius: 10px;
            &::after {
              color: #1c1b1f;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding-top: 0;
        }
      }
    }
  }
}

.objective-btns-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .add-main-btn {
    display: flex;
    width: 100%;
    padding: 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px dashed #cecdcd;
    background: transparent;
    svg {
      width: 18px;
      height: 18px;
    }
    margin-bottom: 8px;
  }
  .save-btn {
    align-self: flex-end;
    display: flex;
    width: 200px;
    padding: 12px 30px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    background: #f6891f;
    border: honeydew;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-transform: capitalize;
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      border-color: #f6891f !important;
      background-color: grey !important;
    }
  }
}
.custom-accordian-data-sheet.accordion
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background: url("../../assets/images/expand_less.svg") !important;
  background-repeat: no-repeat;
  position: absolute;
  top: 15px;
  right: 15px;
}

.add-objectives-btn {
  border-radius: 6px;
  border: 1px dashed #ababab;
  padding: 11px;
  background-color: transparent;
  color: #ababab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
}

.accordion-table {
  & table {
    tbody {
      tr {
        td {
          padding: 16px 0;
          p {
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}
.compilance-header {
  padding: 16px;
  // overflow-y: auto;
  // height: 60vh;
  &::-webkit-scrollbar {
    width: 0;
  }

  .compilance-header-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
    padding: 30px 0 30px 0px;
  }

  .compilance {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 0 16px;
    h4 {
      font-size: 15px;
      font-weight: 600;
      border-top: 1px solid #e4e1e1;
      padding-top: 27px;
      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }
    }
  }

  .input-custom {
    padding: 8px 15px;
    gap: 8px;
    min-width: 328px;
    height: 42px;
    left: calc(50% - 328px / 2 - 223px);
    background: #ededed;
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-top: 8px;
    @media screen and (max-width: 1400px) {
      min-width: 215px;
    }
    &::placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
    &:focus {
      outline: none;
    }
  }
  .input-custom-add {
    padding: 8px 15px;
    gap: 8px;
    min-width: 328px;
    height: 42px;
    left: calc(50% - 328px / 2 - 223px);
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-top: 8px;
    @media screen and (max-width: 1400px) {
      min-width: 215px;
    }
    &::placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
    &:focus {
      outline: none;
    }
  }
}

/* Switch 1 Specific Styles Start */
.switch_box {
  max-width: 100px;
  min-width: 100px;
}

input[type="checkbox"].switch_1 {
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:checked {
    background: #f6891f;
    &:after {
      left: calc(100% - 1.5em);
    }
  }
  &:after {
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
.demand-wrapper {
  .demand-wrapper-ul {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    // margin-left: 12px;
    border-top: 1px solid #e4e1e1;
    padding: 28px 0 20px 0;
    gap: 48px;
  }
  .demand-wrapper-ul-mand {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: flex-end;
    // margin-left: 12px;
    // border-top: 1px solid #e4e1e1;
    padding: 28px 0 20px 0;
    gap: 48px;
  }
}

.note-description {
  background-color: rgba(237, 237, 237, 0.5);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 15px;
  gap: 8px;
  width: 100%;
  height: 210px;
  left: calc(50% - 811px / 2 - 113.5px);
  border-radius: 8px;

  p {
    color: #bbbaba;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
}
.note-description-add {
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 15px;
  gap: 8px;
  width: 100%;
  height: 210px;
  left: calc(50% - 811px / 2 - 113.5px);
  border-radius: 8px;

  p {
    color: #bbbaba;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
}
.notes-btn {
  padding: 12px 30px;
  width: 198px;
  height: 49px;
  background: #f6891f;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
  }
}
.abc-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  position: sticky;
  bottom: 0;
  // padding-bottom: 30px;
  background-color: white;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
.btn-outer-wrapper {
  display: flex;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  padding: 6px;
  width: max-content;
  margin: 15px 0;

  .btn-outer {
    margin: 0;
    width: max-content;
  }
}
.btn-outer {
  width: 146px;
  height: 44px;
  padding: 6px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;

  .View-Behaviour-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    background: #fbf6f1;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #f6891f;
  }
  .View-Behaviour-btn-grey {
    color: grey;
  }
}

.behaviour-name-1 {
  display: grid;
  grid-template-columns: repeat(6, 4fr);
  padding-top: 16px;

  .border-right {
    border-right: 1px solid #dddddd;
    margin-left: 40px;
    @media screen and (max-width: 1400px) {
      margin: 0;
      padding: 8px;
      font-size: 12px;
    }
    &:last-child {
      border: none;
    }
  }
}
.comment-input-wrapper {
  bottom: 50px;
  right: 50px;
  max-width: 278px;
  width: 100%;
  padding-top: 25px;
  @media screen and (max-width: 1400px) {
    max-width: 100%;
  }
  &.custom-input-textarea {
    max-width: 100%;
    .icon-wrapper-span {
      bottom: 0;
      top: unset;
    }
  }
  .custom-comment-input {
    padding: 11px 42px 11px 12px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  textarea {
    height: 200px;
  }
  .icon-wrapper {
    position: relative;
  }
  .icon-wrapper-span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.accordion-table table tbody tr td {
  @media screen and (max-width: 1400px) {
    font-size: 12px;
  }
}
.comment-cancel-btn {
  background: none;
  border: 1px solid red;
  color: red;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
}

.daily-data-sheet-tabs {
  height: 100%;
  .tab-content {
    height: calc(100% - 80px);
    .tab-pane {
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &.active {
        display: flex;
      }
    }
  }
}
