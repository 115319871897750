.MainDataSheetPage {
  display: flex;
  background: #f4f5fe;
  min-width: 100%;
  justify-content: flex-end;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: unset;
  }
  &__Content {
    width: calc(100% - 362px);
    padding: 24px;
    margin-top: 80px;
    margin-right: 362px;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 270px);
      margin-top: 30px;
      margin-right: 250px;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-top: 30px;
      margin-right: 0;
    }
  }
  &__SheetHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__HeadingRow {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__UnlockRequestButton {
        background-color: #ffffff;
        border-color: #f6891f;
        color: #f6891f;
        border-radius: 5px;
        width: 150px;
        height: 44px;
        &:hover {
          background-color: #f6891f;
          border-color: #f6891f;
          color: #ffffff;
        }
      }
    }
  }

  &__DailydataHeading {
    font-size: 24px;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }
  }

  &__clientName {
    display: flex;
    align-items: center;
    margin-right: 50px;
    gap: 20px;
    @media screen and (max-width: 1200px) {
      margin: 0;
      gap: 12px;
      margin-right: 20px;
    }
  }
  &__RightSidebar {
    min-width: 362px;
    width: 362px;
    height: 100vh;
    background: #ffffff;
  }
  &__GoalSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #f9ede3;
    border-radius: 5px;
  }
  &__MainTabs {
    width: 100%;
    background: #ffffff;
    height: calc(100vh - 180px);
    overflow: auto;
    // max-height: 800px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
    margin-top: 24px;
    padding: 30px;
    padding-top: 0;
    @media screen and (max-width: 1200px) {
      padding: 15px;
    }
    :global .custom-tabs-data-sheet {
      position: sticky;
      top: 0;
      left: 0;
      padding-top: 30px;
      background-color: white;
      z-index: 9999;
    }
  }

  &__CustomTabs {
    background-color: red;
  }
  &__SingleCustomTabs {
    background-color: green;
  }
}

.nav-item {
  .nav-link {
    background-color: green !important;
  }
}

.daily-data-heading {
  font-size: 12px;
  font-weight: 700;
  color: red;
}
