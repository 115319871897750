.MainDataSheetPage {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__RightSidebar {
    max-width: 362px;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    padding: 24px;
    padding-right: 0;
    @media screen and (max-width: 1200px) {
      max-width: 250px;
      //   right: 0;
    }
    @media screen and (max-width: 1000px) {
      position: relative;
      height: 500px;
      max-width: 100%;
      padding: 10px;
      margin-bottom: 120px;
    }
  }
  &__RightSidebarWrapper {
    overflow-x: hidden;
    h3 {
      position: sticky;
      top: 0;
      left: 0;
      background: white;
      z-index: 22;
      @media screen and (max-width: 1000px) {
        font-size: 20px;
        padding-left: 10px;
        padding-top: 10px !important;
      }
    }
    @media screen and (max-width: 1000px) {
      height: 100%;
    }
  }
  &__ComentHeading {
    @media screen and (max-width: 1200px) {
      margin-top: 0;
    }
  }
  &__CommentsWrapper {
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 24px;
    @media screen and (max-width: 1000px) {
      height: auto;
      max-width: 100%;
    }
  }
}
