.lm-appointment-calender {
  width: 100% !important;
  border: none !important;
  font-family: "Open Sans" !important;
  display: flex;
  flex-direction: column;
  gap: 40px;
  abbr {
    text-decoration: none !important;
  }
  .react-calendar__navigation__label {
    height: 100% !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .react-calendar__navigation__arrow {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      background-color: transparent !important;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    abbr {
      color: #af79d8 !important;
    }
  }
  .react-calendar__navigation {
    button {
      min-width: fit-content !important;
      &:focus,
      &:disabled {
        background-color: transparent !important;
        color: #73797e;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar__navigation {
    width: 151.33px;
    height: 19px;
    margin-left: auto !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    padding-left: 28px;
    text-align: left !important;
  }
  .react-calendar__tile {
    position: relative;
  }
  .react-calendar__tile- {
    &-active {
      abbr {
        color: #662d91 !important;
        background-color: #f7f1fb !important;
      }
    }
    &-now {
      abbr {
        color: #fff !important;
        background-color: #662d91 !important;
      }
    }
  }

  .tile-lm-calender {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #bbbbbb;
    background-color: transparent !important;
    abbr {
      display: block;
      text-align: center;
      max-width: 57px !important;
      max-height: 38px !important;
      //   background-color: #ececec;
      border-radius: 5px;
      padding: 10px 18px;
    }
  }
}
.appointment-date {
  position: absolute;
  bottom: 7px;
  width: 8px;
  height: 8px;
  background: #f6891f;
  border-radius: 50%;
  left: 31px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
}
