.modal-400 {
  min-width: 400px;
  .modal-content {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    border: none;
  }
  .modal-body {
    padding: 24px;
  }
}
.modal-655 {
  min-width: 655px;
  .modal-body {
    padding: 30px;
  }
}
.modal {
  &-add-btn {
    border-radius: 5px;
    width: 222px;
  }
  .modal-content {
    border-radius: 10px;
    border: none;
  }
  .hide-textarea {
    textarea {
      display: none !important;
    }
  }
}

.director-dropdown .ant-select-selector {
  height: 42px !important;
}

.director-dropdown .ant-select-selector .ant-select-selection-item {
  line-height: normal;
  display: flex;
  align-items: center;
}

.cancellationReasonModal textarea {
  height: 200px;
}

.worked-hours {
  margin-top: 20px;
  margin-bottom: 25px;
}

.hours,
.minutes,
.unit {
  font-size: 22px;
  font-weight: bold;
  margin: 0 5px;
}

.unit {
  font-size: 18px;
  color: #555;
}

.hours,
.minutes {
  background-color: #f6891f;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.worked-hrs-label {
  font-size: 20px;
  color: #555;
  margin-right: 150px;
  font-weight: bold;
}

.tech-hrs-datepickers-div {
  margin-bottom: 40px;
}

.billing-edit-history-modal {
  .modal {
    &-dialog {
      min-width: 60%;
    }
  }
}

.customize-settings-button {
  margin-right: 10px;
  background-color: #ffffff;
  border-color: #f6891f;
  color: #f6891f;
  border-radius: 5px;
}

.restore-default-button {
  margin-right: 10px;
  background-color: #ffffff;
  border-color: #f6891f;
  color: #f6891f;
  border-radius: 5px;
}

.testing-require-modal-content {
  margin-left: 60px;
  margin-right: 20px;
}

.testing-goals-alert-modal-btn {
  background-color: #f6891f;
  width: 70px;
  border: none;
}

.billing-edit-history-table .ant-table {
  height: calc(70vh - 270px) !important;
  // overflow-x: auto !important ;
}

.billing-edit-confirmation-modal {
  .billing-conf-modal-footer button {
    margin-right: 10px;
  }
  .values-container {
    // background-color: greenyellow;
    // margin-left: 40px;
    .values {
      // background-color: #ccc;
      align-items: center;
      gap: 150px;
      .original-value-heading {
        text-align: center;
        .original-value {
          background: #efecec;
          border-radius: 4px;
          padding: 6px;
        }
      }
      .updated-value-heading {
        text-align: center;
        .updated-value {
          background: #efecec;
          border-radius: 4px;
          padding: 6px;
        }
      }
    }
  }
  .modal {
    &-dialog {
      min-width: 35%;
    }
  }
}

.billing-conf-modal-footer {
  padding: 10px 10px;
  border-top: 1px solid #ccc;
  .confirmation-modal-cancel-btn {
    background: none;
    border: 1px solid red;
    color: red;
    padding: 4px 8px;
    border-radius: 8px;
    height: 45px;
    width: 90px;
  }
}
