  .billing_appo_custom_selector {
    width: 110px !important;

    .custom-select-wrapper {
      width: 100% !important;
      display: flex;
      align-items: center; 

      .custom-dropdown-btn {
        margin-top: 0px;
        width: 100%;
      }
    }
  }