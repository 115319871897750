$sky_blue: #61dafb;
$yellowish: rgb(204, 204, 49);
$white: white;
$yellow: rgb(226, 192, 37);
$sky_blue_light: rgb(80, 146, 164);
$orange: rgb(244, 127, 77);
$moongia_green: rgb(134, 166, 121);
$orange_shade: rgb(244, 128, 78);
$dark_grey: #d1d1d1;
$text_color: #666766;

$colors: (
  "bg-pri-light": #f8f7fa,
  "chip-red": #e54545,
  "chip-green": #5eca2b,
  "d-blue": #23235f,
  "black-pri": #000000,
  "orange-pri": #f6891f,
  "purple-pri": #9559c2,
  "b-charcoal": #73797e,
  "s-blue": #61dafb,
  "yellowish-clr": rgb(204, 204, 49),
  "g-grey": rgb(134, 161, 121),
  "white-clr": #fff,
  "g-black": rgb(83, 87, 90),
  "b-grey": #aaaaaa,
  "yellow-clr": rgb(226, 192, 37),
  "org": rgb(244, 127, 77),
  "m-green": rgb(134, 166, 121),
  "org-shade": rgb(244, 128, 78),
  "l-grey": #475467,
  "d-grey": #d1d1d1,
  "d-color": #778399,
  "input-color": #c9cacc,
  "sb-dark": #0a0a0a,
  "sb-light": #4c86a0,
  "red-dark": #aa182c,
  "green-clr": #85a079,
  "dark-grey": #333333,
  "purple-sec": #662d91,
  "grey-blur": #73797e,
  "purple-bold": #af79d8,
  "light-grey-dim": #939393,
  "grey-charchoal": #667085,
  "dark-gray-bold": #101828,
  "span-text": #4B4B4B,
  "td-text": #777777,
  "modal-text": #344054,
  "text-light-gray":#888585,
  "side-bar-heading":#0D1B34,
  "td-last-child":#E54545,

);

@function font_clr($fs_color) {
  @return map-get($colors, $fs_color);
}

@each $color, $val in $colors {
  .clr-#{$color} {
    color: $val !important;
  }
  .bg-clr-#{$color} {
    background-color: $val !important;
  }
  .border-clr-#{$color} {
    border-color: $val !important;
  }
}


$secondary_clr: (
  "dark-red": #aa182c,
  "red-devil": #820012,
  "dark-orng": #df854f,
  "lt-grey": #9d9c9a,
  "lt-blue": #a6c2cf,
  "lt-green": #c2cebc,
  "murcury-clr": #e1e1e1,
  "sec-white": #fff,
  "text-grey": #767676,
 
);

@function Sec_clr($Sec_clr) {
  @return map-get($secondary_clr, $Sec_clr);
}
@each $sec_clr, $val in $secondary_clr {
  .clr-#{$sec_clr} {
    color: $val !important;
  }
  .bg-clr-#{$sec_clr} {
    background-color: $val !important;
  }
}
$primary_clr: (
  "lt-black": #3d3935,
  "cod-grey": #191918,
  "dark-blue": #4c86a0,
  "chathams-blue": #155a79,
  "green-grey": #85a079,
  "highland-green": #759069,
  "chalet-green": #4a653d,
);
@function Pri_clr($Pri_clr) {
  @return map-get($primary_clr, $Pri_clr);
}
@each $pri_clr, $val in $primary_clr {
  .clr-#{$pri_clr} {
    color: $val !important;
  }
  .bg-clr-#{$pri_clr} {
    background-color: $val !important;
  }
}

.bg-white {
  background-color: $white;
}
.fs30 {
  font-size: 1.875rem;
}
.color-sky-blue-light {
  color: $sky_blue_light !important;
}
.bccolor-sky-blue-light,
.bccolor-sky-blue-light:hover {
  border-color: $sky_blue_light !important;
}
.color-yellow {
  color: $yellow !important;
}
.color-white {
  color: $white !important;
}

$box_Shadow: (
  "dark-red": #aa182c,
  "red-devil": #820012,
  "dark-orng": #df854f,
  "lt-grey": #9d9c9a,
  "lt-blue": #a6c2cf,
  "lt-green": #c2cebc,
  "murcury-clr": #e1e1e1,
  "sec-white": #fff,
  "text-grey": #767676,
);

@function get_box_Shadow($clr_pri) {
  @return map-get($box_Shadow, $clr_pri);
}




