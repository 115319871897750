.Appointments {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  &_Wrapper {
    height: 100vh;
    overflow-y: auto;
  }
  &_Heading {
    &_Div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 30px 30px;
      margin-bottom: 1px;
      &_Global_Setting_Btn {
        color: inherit;
        background-color: #ffffff;
        border-color: #bbbbbb;
        &:hover {
          svg {
            fill: white;
          }
        }
      }
    }
  }
  &__Container {
    padding: 0px 30px 50px;
  }
  &__Details {
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    border-radius: 10px;
    min-width: 770px;
    // height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1200px) {
      min-width: 100%;
    }
    // height: 860px;
    &__Heading {
      top: -2px;
    }
    &__CalendarIcon {
      height: fit-content;
      margin-top: -4px
    }
    &__TabWrapper  > div{
      margin: 0px !important;
    }
  }
  &__AddAppointmentSidebar {
    padding: 46px 50px 10px 30px;
    width: 362px;
    min-width: 362px;
    // height: 100vh;
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    @media (max-width: 1200px) {
      padding: 30px;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__AddAppointmentdateinput {
    position: relative;
  }
  &__AddAppointmentinputicon {
    position: absolute;
    right: 16px;
    top: 38px;
    z-index: 9999;
  }
  &__AddAppointmenttimepiker {
    margin-top: 30px;
    position: relative;
  }
  &__AddAppointmentbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    margin-top: 52px;
    background: #f6891f;
    border-radius: 5px;
  }
}

