.Technician-main-wrapper {
  padding: 50px 50px 42px 30px;
  width: 100%;
  position: relative;
  @media (max-width: 1200px) {
    padding: 30px;
    height: 100vh;
    overflow-y: auto;
  }
  .TechnicianHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Technicianname {
    h2 {
      font-size: 28px;
      font-weight: 700;
      @media (max-width: 1200px) {
        font-size: 22px;
      }
    }
  }
  .day-date {
    p {
      font-size: 29px;
      font-weight: 600;
      @media (max-width: 1200px) {
        font-size: 22px;
      }
    }
  }
}

.carousel-outer {
  .carousel-outer-main {
    .carousel-outer-main-item {
      width: 100%;
    }
  }
}

.session-card-outer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 32px;
  .first-session-card {
    box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
    background: #ffffff;
    border-radius: 10px;
    padding: 12px;

    .card-icon {
      width: 48px;
      height: 48px;
      background: #090834;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      text-transform: uppercase;
      p {
        font-weight: 600;
        line-height: 28px;
        font-size: 20px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}

.name-age {
  margin-bottom: 8px;
  .name-joe {
    font-weight: 700;
    line-height: 28px;
    font-size: 18px;
    color: #101828;
  }
  .age-year {
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
    color: #797673;
  }
}
.time-location {
  margin-bottom: 16px;
  .starttime-endtime {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    background: #fff3e7;
    border-radius: 5px;
    margin-bottom: 12px;
    width: 174px;
    white-space: nowrap;
    .timer {
      margin-left: 8px;
      font-weight: 600;
      line-height: 25px;
      font-size: 14px;
      color: #f6891f;
      @media (max-width: 1000px) {
        font-size: 12px;
      }
    }
  }
  .in-location {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    background: #f7edff;
    border-radius: 5px;
    margin-bottom: 12px;
    width: 104px;
    white-space: nowrap;
    .in-clinic {
      margin-left: 8px;
      font-weight: 600;
      line-height: 25px;
      font-size: 14px;
      color: #662d91;
    }
  }
}

.card-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #f6891f;
  border-radius: 5px;
  border: none;
  @media (max-width: 1000px) {
    padding: 12px 15px;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background-color: grey !important;
  }
  // white-space: nowrap;
  .start-session-btn {
    text-decoration: none;
    color: #ffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }
}

.carousel-control-prev {
  left: -4%;
  @media (max-width: 1200px) {
    left: -5%;
  }
}
.carousel-control-next {
  right: -4%;
  @media (max-width: 1200px) {
    right: -5%;
  }
}
.carousel-indicators {
  display: none;
}
.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
  .carousel-control-prev-icon {
    background: url("../../assets/svg/arrow_forward_ios.svg");
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
  .carousel-control-next-icon {
    background: url("../../assets/svg/arrow_forward_ios.svg");
    background-repeat: no-repeat;
  }
}
.Pending-Approval-Sessions-outer {
  margin-top: 24px;
  .Pending-Approval-Sessions-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Pending-Approval-Sessions-title {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
      line-height: 25px;
    }
  }
}

.Pending-Approval-Sessions-linkouter {
  margin-right: 14px;
  .Pending-Approval-Sessions-link {
    font-weight: 600;
    line-height: 30px;
    font-size: 14px;
    color: #f6891f;
    text-decoration: none;
    cursor: pointer;
  }
}

.Approval-Sessions-card-outer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  margin-top: 16px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .Approval-session-card {
    padding: 16px;
    background: #ffffff;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Approval-card-icon-outer {
      display: flex;
      align-items: center;
      gap: 8px;

      .Approval-card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        min-width: 46px;
        min-height: 46px;
        background: #090834;
        border-radius: 100px;
        p {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

.Approval-name-age {
  .Approval-name-joe {
    font-weight: 700;
    line-height: 22px;
    font-size: 16px;
    color: #101828;
  }
  .Approval-age-year {
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    color: #797673;
  }
}
.flag-date-outer {
  display: flex;
  align-items: center;
  gap: 4px;
  .span-date {
    font-weight: 600;
    line-height: 20px;
    font-size: 12px;
    color: #f04438;
  }
}

.tech-table-view-wrapper {
  height: calc(100vh - 650px);
  overflow-y: auto;
  .ant-table {
    height: 100% !important;
  }
}

.no-more-assigned {
  width: 100%;
  height: 370px;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(102, 45, 145, 0.06);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 64px 0;
  .no-more-assigned-text {
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #9a9a9a;
  }
}
