.MainDataSheetDetailPage {
  display: flex;
  background: #f4f5fe;
  min-width: 100%;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: unset;
  }
  &__Content {
    width: calc(100% - 362px);
    @media screen and (max-width: 1200px) {
      width: calc(100% - 263px);
    }
  }
  .checkinoutForm {
    width: 100%;
    form {
      .dashboard-footer {
        width: 100% !important;
      }
    }
  }
}

.breadcrumbWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 50px 0 0 30px;

  &__backArrowBtn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #e8dfef;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
  }
  &__outerRecipent {
    padding: 0 178px 40px 96px;

    overflow-y: auto;
    height: 70%;
    @media screen and (max-width: 1000px) {
      height: 100%;
      width: 700px;
    }
    @media screen and (max-width: 1200px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__RecipientInfo {
    margin-top: 40px;
  }
  &__RecipientInfoInputs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-bottom: 30px;
  }
  &__RecipientInfodates {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  &__RecipientInfoAddress {
    width: 100&;
    margin-bottom: 30px;
  }
  &__RecipientInfoEmail {
    width: 100&;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__RecipientInfoSessionInformation {
    width: 100&;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 16px;
    @media screen and (max-width: 1100px) {
      grid-template-columns: 2fr 2fr 2fr 2fr;
    }
  }
  &__RecipientInfoTechnician {
    width: 100&;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

.ClientInformation {
  &__Datawrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-bottom: 30px;
    column-gap: 24px;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
  }
  &__Dataclinic {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
  }
  &__dataParticipant {
    width: 100&;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }
  &__DatawrapperServiceSession {
    width: 100&;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
  }
  &__DatawrapperBillingType {
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__DatawrapperBeneficiary {
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__DatawrappertargetedAreas {
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.GegreeofProgress {
  &__towardsinputs {
    width: 100&;
    margin-bottom: 12px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 1100px) {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }

    .lm-input-form-input-label {
      color: red !important;
    }
  }
  &__towardsheading {
    height: 60px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #888585;
    margin-bottom: 30px;
  }
  &__towardslabel {
    color: blue;
  }
  &__towardsdotedbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border: 1px dashed #cecdcd;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 45px;
  }
  &__towardsinputs1 {
    width: 100%;
    margin-bottom: 45px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 1100px) {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
  }
  &__towardsSessionsummary {
    max-width: 635px;
    margin-top: 45px;
  }
  &__towardsSessiontextarea {
    display: flex;
    flex-direction: row;
    padding: 8px 15px;
    align-items: center;
    max-width: 636px;
    height: 83px;
    background: #ffffff;
    border: 1px solid #dddddd;
    gap: 8px;
    border-radius: 8px;
    margin-top: 12px;
  }
  &__towardsSignature {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 45px;
  }
  &__towardsguardian {
    display: flex;
    align-items: center;
    height: 170px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
  }
  &__towardsSessionwrapper {
    padding-left: 0;
  }
}
.signaturesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 24px;
  gap: 24px;
  @media screen and (max-width: 1000px) {
    width: 600px;
  }
  :global #signature-pad,
  :global #signature-pad-2 {
    max-width: 300px;
    position: relative;
    .saved-signature-visible {
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      left: 1px;
      height: 152px;
      img {
        border-radius: 8px;
      }
    }
    label {
      color: #aaa;
      margin: 8px;
    }
    canvas {
      border: 1px solid #dddddd !important;
      border-radius: 8px !important;
    }
    .signature-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding-top: 12px;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 6px;
        background: #f6891f;
        border-radius: 6px;
        color: #fff;
        border: none;
        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
  :global .signature-hidden {
    visibility: hidden;
  }
}

.signatureSaveBtn, .signatureClearBtn{
  &:disabled {
    opacity: 0.8;
    background-color: #bbbbbb !important;
  }
}

.checkedinLockedBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 36px; /* Increased width */
  color: #ef3f29;
  background: #f4f5fe;
  border: 1px solid #ef3f29;
  border-radius: 6px;
  margin-right: 70px;
}