.DashboardLogin {
  display: flex;
  &__FormSection {
    min-height: 100vh;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 70px;
    padding-top: 75px;
    &Wrapper {
      min-width: 60%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 70px;
      padding-top: 32px;
      &_Client {
        gap: 121px;
      }
    }
  }
  &__ImageSection {
    background-color: #f4f5fe;
    min-height: 100vh;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &_Wrapper {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
