.dropdown-wrapper {
  .custom-select-wrapper {
    margin: 0 auto;
    width: 280px;
    position: relative;

    .custom-dropdown-btn {
      cursor: pointer;
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 15px;
      background: white;
      border: 1px solid #ddd;
      border-radius: 8px;
      color: #777;
      font-weight: 500;
      margin-top: 8px;
    }
  }
  .custom-dropdown-content {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 8px;
    border-radius: 8px;
    display: block;
    z-index: 9999999;
    background-color: white;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
    .item {
      padding: 10px;
      border-bottom: 1px solid #dddddd;
      margin: 0 8px 0 8px;
      cursor: pointer;
      &:hover {
        background: #fcfcfc;
      }
    }
  }

  .custom-search-input {
    input[type="text"] {
      width: 260px;
      height: 42px;
      padding: 5px 10px 5px 40px;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin: 10px;
      &:focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 30px;
      left: 23px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.dashboard-client-selection {
  .custom-select-wrapper {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.dropstart-wrapper {
  .dropstart {
    position: relative;

    .dropdown-menu {
      position: absolute;
      inset: 0px 0px auto auto;
      transform: translate3d(-26px, 0px, 0px);
      @media screen and (max-width: 1000px) {
        inset: 25px -14px auto auto !important;
        min-width: 100px !important;
      }
    }
    .dropdown-item {
      display: flex;
      gap: 8px;
      width: 10px;
      &:last-child {
        color: red;
        border-top: 1px solid #dddd;
      }
    }
    .dropdown-toggle {
      &::before {
        background: url("../../assets/svg/dotsicon.svg") no-repeat;
        margin: 0;
        right: 0;
        border: none;
        width: 24px;
        height: 24px;
      }
    }
    .btn-primary {
      background-color: transparent;
      display: block;
      border: none;
      height: auto;
      width: 0;
    }
  }
}
