.create-user-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 56px);
}

.create-user-box {
    max-width: 800px;
    width: 100%;
}

.create-user-box .btn-fill-primary{
    /* width: 100%; */
    width: 35%; 
    margin-right: 10px;
}

.add-button{
    margin:10px;
    position: absolute;
    right: 253px;
}

