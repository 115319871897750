.header {
    background-color: white;
    box-shadow: 0px 1px 0px #DCDEE0;
    padding: 5px 0;
}

.nav-items {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    /* justify-content: space-between; */
    list-style-type: none;
}

.nav-item a {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
}

.nav-item:not(:last-child){
    margin-right: 25px;
}

.header-content-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.brand-logo img{
    width: 80px;

}